import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, purple, blue, teal, cyan, deepPurple, brown, deepOrange,lime } from '@mui/material/colors';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import IconButton from '@mui/material/IconButton';


import ProTip from './ProTip';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.matthewbeekman.com" sx={{textDecoration:"none"}}>
        MatthewBeekman.com
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function App(props) {


  var displayLight = [

    <Tooltip key={1} title={(props.light? `Lights Off` : 'Lights On')} placement="bottom" enterNextDelay={300}>
    <IconButton sx={{ml:2}} color="inherit" onClick={() => { props.setLight(!props.light)}}>              
        <EmojiObjectsIcon />
    </IconButton>
    </Tooltip>

  ];

  return (
    <Container sx={{p:1, }}>
      <Box sx={{ p:2, backgroundColor:(props.light ? "#FFFFFF": "#2f2f2f"), border:"1px solid #cccccc",borderColor:(props.light ? "#cccccc": "#5f5f5f"),  borderRadius:3, m:"auto",}}>
      <Grid container  >

        <Grid item xs={12}  sx={{display:"flex", justifyContent:"center"}}>
              <Box sx={{p:0, backgroundImage: {
                xs: `url("head_with_cogs_800.png")`,
              } ,
              backgroundRepeat: `no-repeat`,
              backgroundPositionX:"48%",
              backgroundColor :"#e7e7e8",
              height:"400px",
              width:{ xs:"360px", sm:"475px", md:"800px"} ,
              borderLeft:"6px solid #9e9e8c",
              borderBottom:"6px solid #9e9e8c",
              borderRight:"6px solid #9e9e8c",
              borderTop:"6px solid #9e9e8c",
              borderRadius:5,


              display: 'flex',
              flexDirection: 'column'}}>
              </Box>
        </Grid>

        <Grid item xs={12}  sx={{display:"flex", justifyContent:"center"}}>
          <Typography variant="h4" component="h1" gutterBottom align="center" sx={{mt:4, width:{ xs:"400px", sm:"500px", md:"800px"} , ml:"auto", mr:"auto"}}>
            Matthew Beekman
          </Typography>
        </Grid>
        
        <Grid item xs={12}  sx={{display:"flex", justifyContent:"center"}}>
              <Tooltip title={`Visit Linkedin and get connected`} placement="bottom" enterNextDelay={300}>
              <Box  onClick={() => { window.open("https://www.linkedin.com/in/mbeekman", "_blank")} } sx={{p:2, backgroundImage: {
                xs: `url("linkedin-logo.png")`,
              } ,
              backgroundRepeat: `no-repeat`,
              backgroundSize:"100%",
              height:"50px",
              width:{ xs:"50px", sm:"50px", md:"50px"} ,

              display: 'flex',
              mt:2,
              mb:5,
              cursor:"pointer",
              flexDirection: 'column'}}>
              </Box>
              </Tooltip>
          
        </Grid>
        <Grid item xs={1}  sx={{display:"flex", justifyContent:"center"}}>
          
          {/* SPACER */} 

        </Grid>
        
        <Grid item xs={10}  sx={{display:"flex", justifyContent:"center"}}>
         <Copyright />
        </Grid>
        <Grid item xs={1}  sx={{display:"flex", justifyContent:"center"}}>
          {displayLight}
            
        </Grid>
        
        </Grid>
        
        
      </Box>
    </Container>
  );
}
