import App from './App';
import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.Fragment>
//     {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
//     <App />
//   </React.Fragment>,
//   document.getElementById('root'),
// );




import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

const themeLight = createTheme({
  palette: {
    background: {
      default: "#2f2f2f"
    },
    text: {
      primary: "#2f2f2f",
      secondary: "#2f2f2f"
    }
  }
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#2f2f2f"
    },
    text: {
      primary: "#ffffff",
      secondary: "#efefef"
    }
  }
});

const IndexApp = () => {
  const [light, setLight] = React.useState(true);
  return (
    <ThemeProvider theme={light ? themeLight : themeDark}>
      <CssBaseline />
      {/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}
      <App light={light} setLight={setLight}/> 
    </ThemeProvider>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<IndexApp />, rootElement);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


